<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr><td style="width: 80px;">合同编号：</td>
                            <td><el-input v-model="searchItem.companyName" size="small" placeholder="请输入合同编号"></el-input></td>
                            <td style="width: 80px;">合同类型：</td>
                            <td><el-select style="width:100%" size="small"  v-model="searchItem.type" placeholder="请选择公司" @change="changeCompany">
                                <el-option  key='0' label="全部" :value="0"></el-option>
                                <el-option v-for="item in companyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select></td>
                            <td style="width: 80px;">生成时间：</td>
                            <td><el-date-picker style="width:100%" size="small"  v-model="value2" type="datetimerange" :picker-options="pickerOptions"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
                            </el-date-picker></td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style=" width: 300px ;text-align: right;padding-top: 10px;margin-right:20px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :row-style="{height:'40px'}"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="companyName" label="合同编号" align="center"></el-table-column>
                            <el-table-column prop="name" label="合同类型"  align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="phone" label="合同应收账款(元)" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="idcard" label="已收账款(元)" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="idcard" label="已开票金额(元)" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="idcard" label="财务应收账款(元)" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="idcard" label="公司名称" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <span style="color:#20a0ff" @click="editMaterial(scope.row)">详情</span>
                                        <span style="color:#20a0ff;margin-left:20px" @click="editMaterial(scope.row)">新增回款</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
            <el-dialog :title="dialogName" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
            <div class="dialogdiv">
                <div><p>回款金额：</p><el-input style="width:350px"  v-model="addoption.phone" placeholder="请输入回款金额"></el-input></div>
                <div><p>回款时间：</p><el-input style="width:350px"  v-model="addoption.idcard" placeholder="请输入回款时间"></el-input></div>
                <p style="width:100%;margin-top:15px;font-weight:bold">设备回款情况</p>
                <div style="height:200px;withd:100%;margin-left:0">
                    <div class="deviceCollection">
                        <div><span>设备现场编号</span></div>
                        <div><span>设备类型</span></div>
                        <div><span>设备回款金额</span></div>
                    </div>
                    <div class="deviceCollection" v-for="(item,index) in deviceList" :key="index">
                        <div><p>{{item.senceNo}}</p></div>
                        <div><p>{{item.type==1?"塔机":"升降机"}}</p></div>
                        <div><el-input class="deviceInput" size="small" style="width:100%"  v-model="deviceMoneyList[index]" placeholder="请输入回款金额"></el-input></div>
                    </div>
                </div>
                <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [
                    {
                        id:"123",
                        companyName:"易方汇智",
                        name:"张三",
                        phone:"110",
                        idcard:"21321312"
                    },
                    {
                        id:"234",
                        companyName:"锦城建机",
                        name:"李四",
                        phone:"119",
                        idcard:"1232132131"
                    },
                ],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10, 
                lineHeight: '',  
                searchItem: {
                    companyName: '',
                    personName: '',
                    phone: '',
                    pageIndex: 1,
                    pageSize: 15
                },
                depotType: 0, 
                dialogVisible:false,
                dialogName:"",
                addoption:{
                    id:'',
                    companyName:'',
                    name:'',
                    phone:'',
                    idcard:''
                },
                companyList:[
                    {
                    value: '111',
                    label: '正式合同'
                    }, {
                    value: '222',
                    label: '测试合同'
                    }],
                deviceList:[
                    {
                        senceNo:"1#",
                        type:1,
                        id:1
                    },{
                        senceNo:"2#",
                        type:2,
                        id:2
                    }
                ],
                deviceMoneyList:[],
            };
        },
        methods: {
            getDictionary() {
                // this.$api.getDictionaryData().then(res => {
                //     if (res.code == 200) {
                //         this.useTypeList = res.data.useTypeList;
                //         this.componentList = res.data.componentList;
                //         this.useNatureList = res.data.useNatureList;
                //         this.unitList = res.data.unitList;
                //     }
                // })
            },
            editMaterial(val){
                this.dialogVisible = true;
                this.dialogName="新增回款"
                this.addoption.id = '';
                this.addoption.companyName = '';
                this.addoption.name = '';
                this.addoption.phone = '';
                this.addoption.idcard = '';

            },
            pageChange(option) {
            },
            pageSizeChange(val) {
            },
            searchBtn(){

            },
            reset(){
                this.searchItem.companyName = '';
                this.searchItem.personName = '';
                this.searchItem.phone = '';
            },
        },
        created() {
            this.tableHeight = (window.innerHeight)*0.76
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion{
        display: flex;
        justify-content: center;
    }

    .iconbianji{
        margin-right: 10px;
        color: #20a0ff;
    }
    .iconshanchu{
        margin-left: 10px;
        color: red;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .dialogdiv{
        div{
            text-align: center;
            margin-top: 10px;
        }
        p{
            width: 80px;
            text-align: left;
            display: inline-block;
        }
    }
    .deviceCollection{
        height: 40px;
        width: 100%;
        div{
            width: 30%;
            float: left;
        }
        .deviceInput{
            margin: 0;
            padding: 0;
        }
        p{
            text-align: center;
            margin-top: 8px;
        }
    }

</style>